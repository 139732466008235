exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enquiry-js": () => import("./../../../src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-finance-js": () => import("./../../../src/pages/finance.js" /* webpackChunkName: "component---src-pages-finance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-towbars-js": () => import("./../../../src/pages/towbars.js" /* webpackChunkName: "component---src-pages-towbars-js" */),
  "component---src-pages-trailer-hire-js": () => import("./../../../src/pages/trailer-hire.js" /* webpackChunkName: "component---src-pages-trailer-hire-js" */),
  "component---src-pages-trailer-service-spares-js": () => import("./../../../src/pages/trailer-service-spares.js" /* webpackChunkName: "component---src-pages-trailer-service-spares-js" */),
  "component---src-pages-trailers-js": () => import("./../../../src/pages/trailers.js" /* webpackChunkName: "component---src-pages-trailers-js" */),
  "component---src-pages-used-trailers-js": () => import("./../../../src/pages/used-trailers.js" /* webpackChunkName: "component---src-pages-used-trailers-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/newsPost.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-trailer-category-js": () => import("./../../../src/templates/trailerCategory.js" /* webpackChunkName: "component---src-templates-trailer-category-js" */),
  "component---src-templates-trailer-js": () => import("./../../../src/templates/trailer.js" /* webpackChunkName: "component---src-templates-trailer-js" */),
  "component---src-templates-used-trailer-js": () => import("./../../../src/templates/usedTrailer.js" /* webpackChunkName: "component---src-templates-used-trailer-js" */)
}

